<template>
  <div class="registration">
    <BannerKv :titleEn="BannerKvData.titleEn" :titleZh="BannerKvData.titleZh"
      :backgroundPic="BannerKvData.backgroundPic" />

    <!-- <RegistrationStep :step="2" /> -->

    <section id="registration_message">
      <div class="container registration__container w1000">
        <h5 class="txt-bold txt-center">
          {{ $t("comfirmClinic") }}
        </h5>
        <div class="warning_content">
          <h6 class="txt-theme txt-center">＊{{ $t("doNotRefresh") }}</h6>
        </div>
        <ul class="message_info">
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{ $t("registrationCategory") }} |</span>
              <span>{{ confirmDataText.devision }}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{ $t("registrationDate") }} |</span>
              <span>{{ confirmDataText.date }}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{ $t("registrationTime") }} |</span>
              <span :class="{ 'doctor--red': doctorWang || doctorHong || doctorChen }">{{ confirmDataText.time }}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{ $t("registrationBranch") }} |</span>
              <span>{{ $t(confirmDataText.branch) }}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{ $t("address") }} |</span>
              <span>{{ confirmDataText.address }}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{ $t("doctor") }} |</span>
              <span>{{ translateDoctor }} {{ $t("doctor") }}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{ $t("room") }} |</span>
              <span>{{ $t(confirmDataText.clinic) }} </span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{ $t("remark") }} |</span>
              <span>{{ confirmDataText.remark }} </span>
            </p>
          </li>
        </ul>
      </div>
    </section>

    <div class="w1000 registration__item__container">
      <DoctorNotice class="registration__item__main" />

      <div class="doctor-ads-wrap registration__item__main">
        <DoctorAds />
      </div>
    </div>

    <section id="registration_btnbox">
      <div class="w1000 box">
        <router-link :to="{ name: 'Registration' }" :class="[getLanguage === 'en' ? 'btnEn' : '']" class="btn btn-dark p">
          {{ $t("modifyOutpatient") }}
        </router-link>
        <button type="button" class="btn p" :class="[{ btnDisable: isDisable }, lang === 'en' ? 'btnEn' : '']"
          @click="clickSignUp()">
          {{ $t("confirmOutpatient") }}
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import '@/assets/scss/registration.scss';
import BannerKv from '@/components/BannerKv.vue';
// import RegistrationStep from '@/components/registration/RegistrationStep.vue';
import isDoctorWang from '@/mixins/isDoctorWang';
import isDoctorHong from '@/mixins/isDoctorHong';
import isDoctorChen from '@/mixins/isDoctorChen';
import { devisionZH, timeZH } from '@/utils/const';
import { mapMutations, mapState, mapGetters } from 'vuex';
import { appointment } from '@/lib/cakeHttp';
import { getApiLangCode } from '@/utils/getLangCode';
import DoctorAds from '@/components/registration/DoctorAds.vue';
import DoctorNotice from '@/components/registration/DoctorNotice.vue';
import pic01 from '@/assets/img/index/imglink_1.jpg';
import { formatCustom } from '@/utils/getWeeks';
// import fdsa from '@/registrationProhibitMixin'
import registrationProhibitMixin from '@/registrationProhibitMixin';

export default {
  name: 'RegistrationConfirm',
  components: {
    BannerKv,
    DoctorAds,
    DoctorNotice,
    // RegistrationStep
  },
  mixins: [isDoctorWang, isDoctorHong, isDoctorChen, registrationProhibitMixin],
  data() {
    return {
      BannerKvData: {
        titleEn: 'ONLINE REGISTRATION',
        titleZh: this.$t('onlineBooking'),
        backgroundPic: pic01
      },
      // confirmDataText: {
      //   date: null,
      //   time: null,
      //   devision: null,
      //   branch: null,
      //   doctor: null,
      //   place: null,
      // },
      isDisable: false
    };
  },
  computed: {
    ...mapState(['appointmentData', 'branchs', 'lang', 'doctorMapping']),
    ...mapGetters(['getLanguage']),

    doctorWang() {
      return this.isDoctorWang(this.appointmentData);
    },

    doctorHong() {
      return this.isDoctorHong(this.appointmentData);
    },
    doctorChen() {
      return this.isDoctorChen(this.appointmentData);
    },
    confirmDataText() {
      console.log('this.appointmentData', this.appointmentData);
      if (!this.appointmentData?.uuid) {
        return {};
      }

      let extraShfitTime = false;
      if (this.isDoctorWang(this.appointmentData)) {
        extraShfitTime = 101;
      }

      let remark = '';
      if (!this.appointmentData?.remark) {
        remark = '';
      } else {
        const langCode = getApiLangCode();
        remark = this.appointmentData.remark[langCode];
      }
      if (this.isDoctorHong(this.appointmentData)) {
        extraShfitTime = 102;
      }

      if (this.isDoctorChen(this.appointmentData)) {
        console.log('我是陳醫生');
        extraShfitTime = 103;
      }

      return {
        date: `${this.appointmentData?.date} (${formatCustom(this.appointmentData?.date, 'dd')})`,
        time: timeZH(this.appointmentData?.shift_code, extraShfitTime),
        devision: devisionZH(this.appointmentData?.devision),
        doctor: this.appointmentData?.name,
        branch: this.appointmentData?.branch,
        address: this.appointmentData?.address,
        clinic: this.appointmentData?.clinic,
        doctorId: this.appointmentData?.doctor_id,
        remark: remark || '-',
      };
    },
    translateDoctor() {
      if (this.confirmDataText.doctorId) {
        return this.doctorMapping[String(this.confirmDataText.doctorId)].label;
      }

      return '';
    }
  },
  methods: {
    ...mapMutations(['setAppointmentComplete', 'logIn', 'toggleShowMsg',
      'setMsgInfo']),
    initConfirmDataText() {
      if (!this.appointmentData?.uuid) {
        this.toggleShowMsg(true);
        this.setMsgInfo({
          succeed: false,
          desc: this.$t('refreshError'),
          callback: () => {
            this.$router.push({ name: 'Registration' });
          },
          triggerCallbackOnOverlay: true,
          triggerCallbackOnConfirmBtn: true
        });
      }
    },
    confirmAppointment() {
      const token = this.$cookies.get('api_token');
      const submitData = {
        uuid: this.appointmentData.uuid
      };

      if (
        token &&
        submitData?.uuid
        // submitData.item_id &&
        // submitData.devision_id &&
        // submitData.code &&
        // submitData.date &&
        // submitData.sid
      ) {
        this.isDisable = true;
        // 開始掛號
        appointment(
          submitData,
          // 掛號成功
          (response) => {
            this.setAppointmentComplete(response?.data?.serial_no);
            this.$router.push({ name: 'RegistrationComplete', params: response.data });
          },
          // 掛號失敗
          (message) => {
            this.$customSWAL({ icon: 'error', title: this.$t(message) });
            this.$router.push({ name: 'Registration' });
          }
        );
        // this.isDisable = false;
      } else {
        this.setMsgInfo({
          succeed: false,
          desc: this.$t('lostColumns'),
          callback: () => {
            this.$router.push({ name: 'Registration' });
          },
          triggerCallbackOnOverlay: true,
          triggerCallbackOnConfirmBtn: true
        });
      }
    },
    clickSignUp() {
      if (!this.checkPrivacy() || !this.checkQualificationV3()) {
        this.openkQualificationPop();
        return;
      }

      this.confirmAppointment();
    }
  },
  mounted() {
    this.initConfirmDataText();
  }
};
</script>

<style lang="scss">
.doctor {
  &--red {
    color: #ff2d2d;
  }
}

.doctor-ads-wrap {
  margin-top: -20px;

  &:empty {
    display: none;
  }
}
</style>
